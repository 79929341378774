import {
  IconAddressBook,
  IconBrandFeedly,
  IconLayoutDashboard,
  IconUser,
  IconNews,
  IconWorld,
  IconBrandYoutube,
  IconBooks,
  IconBinoculars,
  IconCertificate,
  IconMessageFilled,
  IconHome,
  IconReportAnalytics,
} from "@tabler/icons-react";
import { ReactNode } from "react";
import { ROUTES } from "../../../enum/routes";

export interface ISideBarMenu {
  link: string;
  label: string;
  icon: ReactNode;
  subMenu?: ISideBarMenu[];
}

export const SIDEBAR_MENU: ISideBarMenu[] = [
  {
    link: ROUTES.ADMINDASHBOARD,
    label: "Dashboard",
    icon: <IconLayoutDashboard />,
  },
  { link: ROUTES.STUDENTS, label: "Students", icon: <IconUser /> },
  { link: ROUTES.NEWSLETTER, label: "Newsletters", icon: <IconBrandFeedly /> },
  {
    link: ROUTES.CONTACT_US,
    label: "Contact Us Forms",
    icon: <IconAddressBook />,
  },
  {
    link: "",
    label: "Home website",
    icon: <IconWorld />,
    subMenu: [
      {
        link: ROUTES.LANDING_PAGE,
        label: "Home Page",
        icon: <IconHome />,
      },
      {
        link: ROUTES.YOUTUBE_PLAYLIST,
        label: "Youtube playlist",
        icon: <IconBrandYoutube />,
      },
      {
        link: ROUTES.JOY_RESULT,
        label: "Joy result",
        icon: <IconReportAnalytics />,
      },
    ],
  },
  {
    link: ROUTES.ALUMNIS,
    label: "Alumnis",
    icon: <IconUser />,
  },
  {
    link: ROUTES.BLOGS,
    label: "Blogs",
    icon: <IconNews />,
  },
  {
    link: ROUTES.COURSES,
    label: "Courses",
    icon: <IconCertificate />,
  },
  {
    link: ROUTES.LEARNING_MODULE,
    label: "Learning module",
    icon: <IconBooks />,
  },
  {
    link: ROUTES.COURSE_REVIEWS,
    label: "Course Reviews",
    icon: <IconBinoculars />,
  },
  {
    link: ROUTES.FAQ,
    label: "FAQs",
    icon: <IconMessageFilled />,
  },
];
