import { Box, Button, Flex, Text } from "@mantine/core";
import React, { Fragment, useMemo, useState } from "react";
import PaginatedTable from "../../components/paginated-table/PaginatedTable";
import { TTableColumns } from "../../Types/table.interface";
import { CONSTANTS } from "../../constants/index.constant";
import YoutubePlayListAddModel from "./components/model/YoutubePlayListAddModel";
import { useDisclosure } from "@mantine/hooks";
import { TABLE_COLUMN } from "../../constants/tables";
import { useGetYoutubePlaylist } from "../../hooks/youtube-playlist/query/getAllYoutubePlaylist.query";
import ActionButton from "./components/action-button/ActionButton";
import { IconPlus } from "@tabler/icons-react";

const YoutubePlayList = () => {
    const [opened, { open, close }] = useDisclosure(false);
  const [page, setPage] = useState(1);
  const { data, isLoading ,refetch} = useGetYoutubePlaylist({
    page,
    limit: CONSTANTS.PAGE_LIMIT,
  });

  console.log("data",data);
  const combinedColumns = useMemo(() => {
    const newColumns = {
      key: "Action",
      label: "Action",
      minWidth: 300,
      renderCell: (value: any) => <ActionButton data={value} refetch={refetch} />,
    };
  
    return [...(TABLE_COLUMN.youtubePlayListcolumn as TTableColumns<unknown>[]), newColumns];
  }, [refetch]);

  const details = useMemo(() => {
    if (!isLoading && data?.status === "success") {
      return (
        (data?.data?.data as []).map((item, index) => ({
          ...(item as unknown as object),
          sno: ((page ?? 1) - 1) * CONSTANTS.PAGE_LIMIT + (index + 1),
        })) ?? []
      );
    }
    return [];
  }, [isLoading, data,page]);
  return (
    <Fragment>
    <Box pr={24}>
      <Flex justify={"space-between"} align={"center"}>
        <Text fw={600} fz={"h3"} my={24}>
          Youtube PlayList
        </Text>
        <Button leftSection={<IconPlus/>} onClick={open}  disabled={details.length >= 4}>Add playlist</Button>
      </Flex>
      <Box>
        <PaginatedTable
          columns={combinedColumns}
          data={details}
          isLoading={isLoading}
          keyExtractor={(e) => {
            const key = e as { _id: string };
            return key._id;
          }}
          paginationProps={{
            page,
            setPage,
            totalDocuments: Number(data?.extraData ?? "0"),
            pageLimit: CONSTANTS.PAGE_LIMIT,
          }}
        />
      </Box>
    </Box>
    <YoutubePlayListAddModel data={{close:close,opened:opened,refetch:refetch}} />
    </Fragment>
  );
};

export default YoutubePlayList;
